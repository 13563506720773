// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { setAppLoading } from '../../redux/loadingReducer'
import { store } from '@store/store'

export const getRequestPending = createAsyncThunk('mvpDocument/user/request/pending', async data => {
  store.dispatch(setAppLoading(true))
    const response = await axios.get('/user/request/pending', { params: data })
    store.dispatch(setAppLoading(false))
    return response.data.data
  })

  export const mvpDocumentSlice = createSlice({
    name: 'mvpDocument',
    initialState: {
        mvpDocument : {
            requestPending:[]
          }
    },
    reducers: {},
    extraReducers: builder => {
      builder
        .addCase(getRequestPending.fulfilled, (state, action) => {
          state.mvpDocument.requestPending = action.payload
        })
    }
  })
  
  export default mvpDocumentSlice.reducer