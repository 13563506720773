// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getPreviousSaminarData = createAsyncThunk('seminars/getData', async params => {
  const response = await axios.get(`/seminar/previous?user=${params.user}&limit=${params.perPage}&page=${params.page}&search=${params.q ? params.q : ''}&sortOrder=${params.sort ? params.sort : ''}&sortColumn=${params.sortColumn ? params.sortColumn : ''}`)
  return {
    params,
    data: response.data.data,
    totalPages: response.data.pagination.noOfPages,
    totalData:response.data.pagination.total
  }
})

export const getSeminar = createAsyncThunk('seminars/getSeminar', async id => {
  const response = await axios.get('/api/seminar', { id })
  return response.data.data
})

export const seminarSlice = createSlice({
  name: 'seminars',
  initialState: {
    data: [],
    total: 1,
    totalData:0,
    params: {},
    allData:[]
  },
  reducers: {},
  extraReducers: builder => {
    builder
    .addCase(getPreviousSaminarData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.totalPages
      state.totalData = action.payload.totalData
    })
  }
})

export default seminarSlice.reducer
