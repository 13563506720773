import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const setAppLoading = createAsyncThunk('loader/setAppLoading', loading => {
    return loading
})

export const loaderSlice = createSlice({
    name: 'loader',
    initialState: {
        isloading : false
    },
    reducers: {},
    extraReducers: builder => {
        builder
         .addCase(setAppLoading.fulfilled, (state, action) => {
            state.isloading = action.payload
         }) 
    } 
})

export default loaderSlice.reducer