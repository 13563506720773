// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { setAppLoading } from '../../redux/loadingReducer'
import { store } from '@store/store'

export const getProfileDetails = createAsyncThunk('appUsers/getUserProfile', async data => {
  store.dispatch(setAppLoading(true))
    const response = await axios.get('/user/profile', { params: data })
    store.dispatch(setAppLoading(false))
    return response.data.data
  })
  
  export const getUserRequest = createAsyncThunk('appUsers/getUserRequest', async (params) => {
    store.dispatch(setAppLoading(true))
    const response = await axios.get(`/user/request/pending?user=${params.user}&status=${params.status ? params.status : ''}&page=${params.page}&limit=${params.limit}&search=${params.q}&sortOrder=${params.sort}&sortColumn=${params.sortColumn}`)
    store.dispatch(setAppLoading(false))
    return response.data
  })
  
  export const appUsersSlice = createSlice({
    name: 'appUsers',
    initialState: {
      userProfile:null,
      userRequestList : [],
      useRequestTotal : 1
    },
    reducers: {},
    extraReducers: builder => {
      builder
        .addCase(getProfileDetails.fulfilled, (state, action) => {
          state.userProfile = action.payload
        })
        .addCase(getUserRequest.fulfilled, (state, action) => {
          state.userRequestList = action.payload.data
          state.useRequestTotal = action.payload.pagination.noOfPages
        })
    }
  })
  
  export default appUsersSlice.reducer