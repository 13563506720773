// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getSaminarData = createAsyncThunk('upcomingSeminars/getData', async params => {
  const response = await axios.get(`/seminar/upcoming?user=${params.user}&limit=${params.perPage}&page=${params.page}&search=${params.q ? params.q : ''}&sortOrder=${params.sort ? params.sort : ''}&sortColumn=${params.sortColumn ? params.sortColumn : ''}`)
  return {
    params,
    data: response.data.data,
    totalPages: response.data.pagination.noOfPages,
    totalData:response.data.pagination.total
  }
})

export const getSeminarDetails = createAsyncThunk('upcomingSeminars/getUser', async _id => {
  const response = await axios.get(`/seminar/details?_id=${_id}`)
return response.data
})

export const updateSeminar = createAsyncThunk('upcomingSeminars/updateSeminar', async (data) => {
  const response = await axios.put('/seminar', data)
  return response.data
})

export const addUser = createAsyncThunk('upcomingSeminars/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('upcomingSeminars/deleteUser', async (_id, { dispatch, getState }) => {
 const response =  await axios.delete('/seminar',  { data: { _id } })
  await dispatch(getSaminarData(getState().users.params))
  return response.data
})

export const getAttendees = createAsyncThunk('seminar/getAttendees', async (params) => {
  const response = await axios.get(`/seminar/attendees?seminar=${params.seminar}&limit=${params.perPage}&page=${params.page}&search=${params.q}&sortOrder=${params.sort}&sortColumn=${params.sortColumn}`)
  return response.data
})

export const getFeedback = createAsyncThunk('seminar/feedback', async (params) => {
  const response = await axios.get(`/seminar/feedback?seminar=${params.seminar}&limit=${params.perPage}&page=${params.page}&search=${params.q}&sortOrder=${params.sort}&sortColumn=${params.sortColumn}`)
  return response.data
})

export const updateAttendeesPresent = createAsyncThunk('seminar/attendance/present', async (data) => {
  const response = await axios.put('/seminar/attendance/present', data)
  return response.data
})

export const dashboardCount = createAsyncThunk('seminar/dashboard/count', async (data) => {
  const response = await axios.get(`/seminar/dashboard/count?seminar=${data.seminar}`)
  return response.data
})

export const seminarVerification = createAsyncThunk('/seminar/auth/verification', async (data) => {
  const response = await axios.get(`/seminar/auth/verification?_id=${data.seminar}`)
  return response.data
})

export const seminarFeedback = createAsyncThunk('seminar/feedback/add-feedback/', async (data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${data.token}`
    }
  }
  const response = await axios.post('/seminar/feedback', data, config)
  return response.data
})

export const getAttendanceRegisteredList = createAsyncThunk('/seminar/attendace/registered/list', async (data) => {
  const response = await axios.get(`/seminar/attendance/registered?seminar=${data.seminar}`)
  return response.data
})

export const upcomingSeminarSlice = createSlice({
  name: 'upcomingSeminars',
  initialState: {
    data: [],
    total: 1,
    totalData:0,
    params: {},
    allData: [],
    seminarDetails :null,
    dashboard:null,
    attendees : [],
    feedback : [],
    pagination : {
      total : 1
    }
  },
  reducers: {},
  extraReducers: builder => {
    builder
    .addCase(getSaminarData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.totalPages
      state.totalData = action.payload.totalData
    })
    .addCase(getSeminarDetails.fulfilled, (state, action) => {
      state.seminarDetails = action.payload.data
    })
    .addCase(getAttendees.fulfilled, (state, action) => {
      state.attendees = action.payload.data
      state.pagination.total = action.payload.pagination.noOfPages
    })
    .addCase(getFeedback.fulfilled, (state, action) => {
      state.feedback = action.payload.data
      state.pagination.total = action.payload.pagination.noOfPages
    })
    .addCase(dashboardCount.fulfilled, (state, action) => {
      state.dashboard = action.payload.data
    })
  }
})

export default upcomingSeminarSlice.reducer
