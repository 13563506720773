// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'

export const getKitDocuments = createAsyncThunk('/kit/documents', async data => {
    const response = await axios.get('/kit/documents', { params: data })
    return response.data.data
  })

  export const getKitCertificate = createAsyncThunk('/kit/certificate', async data => {
    const response = await axios.get('/kit/certificate', { params: data })
    return response.data.data
  })

  export const  kitManagementSlice = createSlice({
    name: 'KitManagement',
    initialState: {
          kitDocuments : [],
          kitCertificate : null
    },
    reducers: {},
    extraReducers: builder => {
      builder
        .addCase(getKitDocuments.fulfilled, (state, action) => {
          state.kitDocuments = action.payload
        })
        .addCase(getKitCertificate.fulfilled, (state, action) => {
          state.kitCertificate = action.payload
        })
    }
  })
  
  export default kitManagementSlice.reducer