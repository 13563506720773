// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import users from '@src/views/apps/user/store'
import userProfile from  '@src/@services/userReducer'
import email from '@src/views/apps/email/store'
import invoice from '@src/views/apps/invoice/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'
import seminars from '@src/@services/seminarReducer'
import upcomingSeminars from '@src/views/session/store'
import mvpDocument from  '@src/@services/documentReducer'
import kitManagement from '@src/@services/kitManagementReducer'
import loader from './loadingReducer'

const rootReducer = {
  auth,
  todo,
  chat,
  email,
  users,
  userProfile,
  loader,
  upcomingSeminars,
  seminars,
  mvpDocument,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions,
  kitManagement
}

export default rootReducer
